import { useContext, useState } from "react";
import Modal from "./Modal";
import { AppContext } from "./AppContext";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { object, string } from 'yup';


const LoginForm = ({ handleOnClear, register, handleOnClose, isModalOpen }) => {

  const [validateMessage, setValidateMessage] = useState('');

  const { setUser } = useContext(AppContext);

  const resetStateOfInputs = () => {
    handleOnClose();
    setValidateMessage('');
    formik.handleReset();

  }

  const sendLoginPassword = async (login, password) => {
    try {
      const response = await fetch("/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ login, password })
      })
      if (!response.ok) {
        const message = await response.json();
        // console.log(message.error + ' !');
        setValidateMessage(message.error + ' !');
        return;
      }
      const user = await response.json();
      // console.log('Success, user:', user)
      setUser(user);
      resetStateOfInputs();
    }
    catch (error) {
      console.error(error);
    }
  }

  const sendRegisterLoginPassword = async (login, password) => {
    try {
      const response = await fetch("/users/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ login, password })
      })
      if (!response.ok) {
        const message = await response.json()
        // console.log(message.error + ' !');
        setValidateMessage(message.error + ' !');
        return;
      }
      const registeredUser = await response.json();

      if (registeredUser) {
        // console.log('Sucess, registered user:', registeredUser)
        formik.handleReset();
        handleOnClear();
      } else return;
    }
    catch (error) {
      console.error(error);
    }
  }

  const validateMesageComponent = validateMessage.length ? <p className="loginForm--validate-message">{validateMessage}</p> : null;


  const formik = useFormik(
    {
      initialValues: {
        login: "",
        password: "",
      },
      validationSchema: object({
        login: string().required('Please enter login').max(15, 'Login must be 15 characters or less'),
        password: string().required('Please enter password').max(20, 'Password must be 20 characters or less'),
      }),
      onSubmit: values => { register ? sendRegisterLoginPassword(values.login, values.password) : sendLoginPassword(values.login, values.password) }
    }
  )

  return (
    <Modal handleOnClose={handleOnClose} isOpen={isModalOpen} shouldBeClosedOnOutsideClick={false} >

      <form className="loginForm" onSubmit={formik.handleSubmit}>

        {validateMesageComponent}

        <div className="loginForm--validate-message">{formik.touched.login && formik.errors.login ? formik.errors.login : null}</div>

        {formik.touched.password && formik.errors.password ? <div className="loginForm--validate-message">{formik.errors.password}</div> : null}

        <div className="loginForm--row">
          <label htmlFor="loginInput">{register ? 'Set login:' : 'login:'}</label>
          <input
            // required
            autoFocus={false} /* nie działa */
            // maxLength={16}
            id='loginInput'
            name='login'
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.login}
          />
        </div>

        <div className="loginForm--row">
          <label htmlFor="passwordInput">{register ? 'Set password' : 'password:'}</label>
          <input
            // required
            autoFocus={false}
            // maxLength={21}
            id='passwordInput'
            name='password'
            type="password"
            autoComplete="off"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
        </div>

        <div className="loginForm--row">

          <button className="button s primary" type='submit'>{register ? 'REGISTER' : 'LOG IN'}</button>

          <button
            className="button s primary"
            type='button'
            onClick={(e) => {
              resetStateOfInputs();
              handleOnClear();
            }}>CANCEL</button>

        </div>
      </form>
    </Modal>
  );
}
export default LoginForm;