import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import { useFormik } from 'formik';
import { object, string, mixed } from 'yup';
import { useContext, useRef, useState } from "react";
import { AppContext } from "./AppContext";


const PictureForm = ({ isEditMode = true, isOpenForm, hideForm, _id, name, title, text, pageSize, pictures }) => {


  const { currentPage, setCurrentPage } = useContext(AppContext);
  const [validateMessage, setValidateMessage] = useState('');



  const navigate = useNavigate();
  const input_ref = useRef();

  const formik = useFormik({
    initialValues: {
      // formName: name,
      name: {},
      formTitle: title,
      formText: text,
    },
    validationSchema: object({
      name: mixed()
        .test('file_exists', 'File is missing', (value) => {
          if (Boolean(!value.name) && !isEditMode) {
            return false;
          }
          else if (Boolean(!value.name) && isEditMode) { return true }
          else { return true };
        })
        .test('file_size', 'Uploaded file is too big.', (value) => {
          const file_size = 8000000;
          if (Boolean(value.name)) {
            return value.size <= file_size ? true : false
          } else return true;
        })
        .test('file_format', 'Only jpg, jpeg or png files are allowed', (value) => {
          const supportedFormats = ['jpg', 'jpeg', 'png'];
          if (Boolean(value.name)) {
            if (supportedFormats.includes(value.name.split(".").pop())) { return true }
            else return false;
          }
          return true;
        }),

      formTitle: string().required('Please enter title').min(3, 'Title must be 3 characters or more').max(20, 'Title must be 20 characters or less'),

      formText: string().max(600, 'Text must be 600 characters or less'),
    }),

    onSubmit: values => {

      const formData = new FormData();


      formData.append('title', values.formTitle || "");
      formData.append('text', values.formText || "");
      formData.append('name', values.name || "");

      if (isEditMode) { editPicture(formData) } else {
        addPicture(formData);
      }
    },
    enableReinitialize: true,
  });


  const resetStateOfInputs = () => {
    formik.handleReset();
    setValidateMessage('');
    input_ref.current.value = "";
    input_ref.current.type = "text";
    input_ref.current.type = "file";
  }

  async function editPicture(formData) {
    try {
      // const response = await fetch(`http://127.0.0.1:3001/pictures/${_id}`, {
      //   method: "PUT",
      //   body: formData,
      // });
      const response = await fetch(`/pictures/${_id}`, {
        method: "PUT",
        body: formData,
      });

      if (!response.ok && response.status === 404) {
        const message = `Record with id ${_id} ${response.statusText.toLowerCase()}`
        // console.log(message);
        setValidateMessage(message);
        return;
      } else if (!response.ok && response.status === 400) {
        const message = await response.json();
        // console.log(message.error);
        setValidateMessage(message.error);
        return;
      }
      // const item = await response.json();
      // console.log("Success, changed picture:", item);
      hideForm();
      resetStateOfInputs();
      navigate(`/scope/pictures/${_id}`, { state: { currentPage, pageSize } }, { replace: true });
    }
    catch (error) {
      console.error(error);
    }
  }

  async function addPicture(formData) {

    try {
      const response = await fetch("/pictures", {
        method: "POST",
        body: formData,
      })
      if (!response.ok) {
        const message = await response.json();
        // console.log(message.error);
        setValidateMessage(message.error);
        return;
      }
      // const item = await response.json();
      // console.log("Success, added picture:", item);
      let currentPage = Math.ceil((pictures.length + 1) / pageSize);
      setCurrentPage(currentPage);
      hideForm();
      resetStateOfInputs();
      navigate("/scope/pictures/", { replace: true });
    }
    catch (error) {
      console.error(error);
    }
  }

  return (
    <Modal handleOnClose={hideForm} isOpen={isOpenForm}
    /* shouldBeClosedOnOutsideClick={true} */ >
      <div className="item">
        <h1>{isEditMode ? "Picture edit" : "Picture add"}</h1>
        {validateMessage && <p style={{ color: 'red', fontWeight: '600' }} >{validateMessage}</p>}
        <form action="" className="item--form" onSubmit={formik.handleSubmit}>
          <input
            className="item--form-file"
            type="file"
            ref={input_ref}
            // accept=".png,.jpg,.jpeg"
            onChange={(e) => formik.setFieldValue('name', e.currentTarget.files[0])}
          />
          {formik.touched.name && formik.errors.name ? <div className="item--validate-message">{formik.errors.name}</div> : null}

          <label>
            Title:
            <input
              // required
              placeholder="title"
              type="text"
              name="formTitle"
              value={formik.values.formTitle || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="item--form-input"
            />
          </label>

          {formik.touched.formTitle && formik.errors.formTitle ? <div className="item--validate-message">{formik.errors.formTitle}</div> : null}

          <label>
            Text:
            <textarea
              // required
              placeholder="Enter text"
              name="formText"
              rows={5}
              columns={20}
              value={formik.values.formText || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="item--form-input"
            />
          </label>
          {formik.touched.formText && formik.errors.formText ? <div className="item--validate-message">{formik.errors.formText}</div> : null}

          <button className="button s" type='submit'>{isEditMode ? 'SUBMIT' : 'ADD'}</button>
        </form>
        <button
          type='button'
          className="button s"
          onClick={() => {
            resetStateOfInputs();
            hideForm();
          }}
        >CLOSE FORM</button>
      </div>
    </Modal>
  );
}

export default PictureForm;




