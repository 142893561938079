import { useContext } from "react";
import { AppContext } from "./AppContext";
import { Navigate, Route, Routes } from 'react-router-dom';

import Pictures from "./Pictures";
import Picture from "./Picture";


const RightSideComp = ({ title }) => {
  const { user } = useContext(AppContext);
  return (
    <div className={`main--${title}`}>
      {/* <h2>{title}</h2> */}
      <Routes>
        {user && <Route path="pictures/*" element={<Pictures />} />}
        {user && <Route path="pictures/:id" element={<Picture />} />}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default RightSideComp;