
import SplitScreen from "./Split-screen";
import MiddleComp from "./MiddleComp";
import LeftSideComp from "./LeftSideComp";
import RightSideComp from "./RightSideComp";
import { useLocation } from "react-router-dom";

const Desktop = () => {

  let location = useLocation();

  const id = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);

  let left;
  let middle;
  let right;

  switch (location.pathname) {
    case '/scope/contact':
      left = 1;
      middle = 1;
      right = 0;
      break;
    case '/scope/offer':
      left = 1;
      middle = 1;
      right = 0;
      break;
    case `/scope/offer/${id}`:
      left = 1;
      middle = 1;
      right = 0;
      break;
    case '/scope/pictures':
      left = 0;
      middle = 0;
      right = 1;
      break;
    case `/scope/pictures/${id}`:
      left = 0;
      middle = 0;
      right = 1;
      break;
    default:
      left = 1;
      middle = 1;
      right = 1;
  }

  return (
    <SplitScreen leftWidth={left} middleWidth={middle} rightWidth={right}>
      {Boolean(left) && <LeftSideComp title='leftDesktop' />}
      {Boolean(middle) && <MiddleComp title='middleDesktop' />}
      {Boolean(right) && <RightSideComp title='rightDesktop' />}
    </SplitScreen>
  )
}

export default Desktop;
