

import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const Modal = ({ children, handleOnClose, isOpen, shouldBeClosedOnOutsideClick }) => {

  const modalRef = useRef(null);
  const previousActiveElement = useRef(null);
  previousActiveElement.current = document.activeElement;


  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    const { current: modal } = modalRef;

    if (isOpen) {
      modal.showModal();
    }

    else if (previousActiveElement.current) {
      modal.close();
      previousActiveElement.current.focus();
    }
  }, [isOpen]);


  /* użycie ESC key */
  useEffect(() => {
    const { current: modal } = modalRef;

    const handleCancel = event => {
      event.preventDefault();
      handleOnClose();
    };
    modal.addEventListener('cancel', handleCancel)
    return () => {
      modal.removeEventListener('cancel', handleCancel)
    }
  }, [handleOnClose])/* zamknięcie przy pomocy klawisza ESC */


  const handleOutsideClick = ({ target }) => {
    const { current } = modalRef;

    if (shouldBeClosedOnOutsideClick && target === current) {
      handleOnClose();
    }
  }

  /* zamyka się po kliknięciu poza Modal-em */

  return createPortal(
    /* element natywny html-a */
    <dialog className="modal modal--animation" ref={modalRef} onClick={handleOutsideClick}>
      {children}
    </dialog>, document.body);
}

export default Modal;