import { useContext } from "react";
import { useEffect } from "react";
import { AppContext } from "./AppContext";
import { Route, Routes, useLocation } from "react-router-dom";


let content;

const MatchMediaWrapper = ({ TabletContent, DesktopContent, PhoneContent }) => {

  const { isDesktopScreen, setIsDesktopScreen, setIsDesktopScreen_1, setIsDesktopScreen_2, isTabletScreen, setIsTabletScreen, setIsTabletScreen_1, setIsTabletScreen_2, isPhoneScreen, setIsPhoneScreen } = useContext(AppContext);

  useEffect(() => {
    const mediaWatcherDesktop_2 = window.matchMedia('(min-width: 1201px)');
    const mediaWatcherDesktop_1 = window.matchMedia('(min-width: 993px) and (max-width:1200px');

    const mediaWatcherTablet_2 = window.matchMedia('(min-width: 769px) and (max-width:992px');
    const mediaWatcherTablet_1 = window.matchMedia('(min-width: 577px) and (max-width:768px');

    const mediaWatcherDesktop = window.matchMedia('(min-width: 993px)');
    const mediaWatcherTablet = window.matchMedia('(min-width: 577px) and (max-width:992px');
    const mediaWatcherPhone = window.matchMedia('(max-width:576px')

    setIsDesktopScreen_2(mediaWatcherDesktop_2.matches);
    setIsDesktopScreen_1(mediaWatcherDesktop_1.matches);

    setIsTabletScreen_2(mediaWatcherTablet_2.matches);
    setIsTabletScreen_1(mediaWatcherTablet_1.matches);

    setIsDesktopScreen(mediaWatcherDesktop.matches);
    setIsTabletScreen(mediaWatcherTablet.matches);
    setIsPhoneScreen(mediaWatcherPhone.matches);

    function updateIsDesktopScreen_2(e) {
      setIsDesktopScreen_2(e.matches);
    }

    function updateIsDesktopScreen_1(e) {
      setIsDesktopScreen_1(e.matches);
    }

    function updateIsDesktopScreen(e) {
      setIsDesktopScreen(e.matches);
    }

    function updateIsTabletScreen_2(e) {
      setIsTabletScreen_2(e.matches);
    }

    function updateIsTabletScreen_1(e) {
      setIsTabletScreen_1(e.matches);
    }

    function updateIsTabletScreen(e) {
      setIsTabletScreen(e.matches);
    }

    function updateIsPhoneScreen(e) {
      setIsPhoneScreen(e.matches);
    }

    if (mediaWatcherDesktop.addEventListener || mediaWatcherDesktop_1.addEventListener || mediaWatcherDesktop_2.addEventListener || mediaWatcherTablet.addEventListener || mediaWatcherTablet_1.addEventListener || mediaWatcherTablet_2.addEventListener || mediaWatcherPhone.addEventListener) {

      mediaWatcherDesktop_2.addEventListener('change', updateIsDesktopScreen_2)
      mediaWatcherDesktop_1.addEventListener('change', updateIsDesktopScreen_1)
      mediaWatcherDesktop.addEventListener('change', updateIsDesktopScreen)


      mediaWatcherTablet_2.addEventListener('change', updateIsTabletScreen_2)
      mediaWatcherTablet_1.addEventListener('change', updateIsTabletScreen_1)
      mediaWatcherTablet.addEventListener('change', updateIsTabletScreen)
      mediaWatcherPhone.addEventListener('change', updateIsPhoneScreen)

      return function cleanup() {
        mediaWatcherDesktop_2.removeEventListener('change', updateIsDesktopScreen_2);
        mediaWatcherDesktop_1.removeEventListener('change', updateIsDesktopScreen_1);
        mediaWatcherDesktop.removeEventListener('change', updateIsDesktopScreen);
        mediaWatcherTablet.removeEventListener('change', updateIsTabletScreen);
        mediaWatcherPhone.removeEventListener('change', updateIsPhoneScreen)
      }
    } else {
      // backwards compatibility
      mediaWatcherDesktop_2.addListener(updateIsDesktopScreen_2);
      mediaWatcherDesktop_1.addListener(updateIsDesktopScreen_1);
      mediaWatcherDesktop.addListener(updateIsDesktopScreen);
      mediaWatcherTablet_2.addListener(updateIsTabletScreen_2);
      mediaWatcherTablet_1.addListener(updateIsTabletScreen_1);
      mediaWatcherTablet.addListener(updateIsTabletScreen);
      mediaWatcherPhone.addListener(updateIsPhoneScreen);
      return function cleanup() {
        mediaWatcherDesktop_2.removeListener(updateIsDesktopScreen_2);
        mediaWatcherDesktop_1.removeListener(updateIsDesktopScreen_1);
        mediaWatcherDesktop.removeListener(updateIsDesktopScreen);
        mediaWatcherTablet_2.removeListener(updateIsTabletScreen_2);
        mediaWatcherTablet_1.removeListener(updateIsTabletScreen_1);
        mediaWatcherTablet.removeListener(updateIsTabletScreen);
        mediaWatcherPhone.removeListener(updateIsPhoneScreen)
      }
    }
  }, [setIsDesktopScreen, setIsDesktopScreen_1, setIsDesktopScreen_2, setIsPhoneScreen, setIsTabletScreen, setIsTabletScreen_1, setIsTabletScreen_2])

  if (isDesktopScreen) { content = <DesktopContent color={'yellow'} /> } else if (isTabletScreen) { content = <TabletContent color={'lightgreen'} /> } else if (isPhoneScreen) { content = <PhoneContent color={'lightpink'} /> }


  const location = useLocation();

  const id = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);

  let main;

  switch (location.pathname) {
    case '/scope/pictures':
      main = 'url("/images/evening.jpg")';
      break;
    case `/scope/pictures/${id}`:
      main = 'url("/images/evening.jpg")';
      break;
    default:
      main = 'url("/images/city2.jpg")';
  }

  return (
    <Routes>
      <Route path="/*"
        element={
          <div
            className="main"
            style={{ backgroundImage: location.pathname !== '/' && `${main}` }}
          >
            <div className="container">
              {content}
            </div>
          </div>
        } />
    </Routes>
  )
}

export default MatchMediaWrapper;