
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { useContext } from "react";
import { AppContext } from "./AppContext";
import { navs } from './navsData';

const Media = () => {

  const { side, setSide, setTab } = useContext(AppContext)

  const nav = navs.find(nav => nav.title === 'CONTACT')

  const handleClick = () => {
    setSide(!side)
    setTab(nav.id);
  }

  return (
    <div className="media">
      <div className="media--contact">
        <div className="media--contact-email">
          <FontAwesomeIcon icon={faEnvelope} />
          <a href="mailto:example@email.com">example@email.com</a>
        </div>
        <div className='media--contact-phone'>
          <FontAwesomeIcon icon={faPhone} />
          <p>+48 123 456 789</p>
        </div>
      </div>
      <div className="media--links">
        <Link
          to='scope/contact'
          onClick={handleClick}
        >Contact Us</Link>
      </div>
    </div >
  );
}

export default Media;