import { usePagination, leftDOTS, rightDOTS } from './usePagination';


const Pagination = props => {
  const {
    totalCount,
    pageSize,
    siblingCount = 1,
    currentPage,
    onPageChange,
  } = props;

  const paginationRange = usePagination({
    totalCount,
    pageSize,
    siblingCount,
    currentPage,
  });

  // If there are less than 2 times in pagination range the component will not be rendered
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];


  return (
    <ul
      className='pagination-container'
    >
      {/* Left navigation arrow */}
      <li
        key={'left'}
        className={currentPage !== 1 ? 'pagination-item' : ''}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>


      {paginationRange.map(pageNumber => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === leftDOTS) {
          return <li key='a' className="pagination-item dots">&#8230;</li>;
        } else if (pageNumber === rightDOTS) {
          return <li key='b' className="pagination-item dots">&#8230;</li>
        }

        // Render Page Pills
        return (
          <li
            key={pageNumber}
            className={pageNumber === currentPage ? 'pagination-item' : "pagination"}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      {/*  Right Navigation arrow */}

      <li
        key={'right'}
        className={currentPage !== lastPage ? 'pagination-item' : ''}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;