import PictureCard from "./PictureCard";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "./AppContext";
import PictureForm from "./PictureForm";
import { useLocation } from "react-router-dom";
import Pagination from "./Pagination";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Pictures = () => {

  const [isOpenForm, setIsOpenForm] = useState(false);

  const refPicture = useRef();

  const { currentPage, setCurrentPage, user, isAdmin, pictures, setPictures, isPhoneScreen, isTabletScreen_1, isTabletScreen_2, isDesktopScreen_1 } = useContext(AppContext);

  const location = useLocation();

  const showForm = () => setIsOpenForm(true);
  const hideForm = () => setIsOpenForm(false);

  let pageSize;

  if (isPhoneScreen) { pageSize = 2 } else if (isTabletScreen_1) { pageSize = 2 } else if (isTabletScreen_2) { pageSize = 4 } else if (isDesktopScreen_1) { pageSize = 4 } else { pageSize = 6 }


  useEffect(() => {

    async function getPictures() {

      const response = await fetch("/pictures");

      if (!response.ok) {
        const message = `An error occurred:${response.statusText}`
        console.log(message);
        return;
      }
      const pictures = await response.json();
      // console.log('Success, got pictures:', pictures);
      setPictures(pictures);
    }
    getPictures();
    return;
  }, [pictures.length, location, setPictures]);


  const currentTableData = useMemo(() => {
    let firstPageIndex = (currentPage - 1) * pageSize;

    if (firstPageIndex > pictures.length) {
      firstPageIndex = 0
      setCurrentPage(1);
    }

    let lastPageIndex = firstPageIndex + pageSize;

    return pictures.slice(firstPageIndex, lastPageIndex);

  }, [currentPage, pictures, pageSize, setCurrentPage]);


  const pictureCards = currentTableData.map(picture => (
    <PictureCard key={picture._id} picture={picture} currentPage={currentPage} refPicture={refPicture} pageSize={pageSize} />
  ));
  useGSAP(() => {
    if (!refPicture.current) {
      return;
    }
    gsap.from(".pictures--picture", {
      duration: 1.4,
      opacity: 0,
      delay: 0.2,
      stagger: 0.2,
    }
    )
    gsap.from(".pictures--picture-title", {
      duration: 1.4,
      opacity: 0,
      delay: 1.0,
      stagger: 0.2,
    }
    )
  }, [currentPage])

  return (
    <div
      className="pictures"
      style={{ marginTop: `${user && !isAdmin && '10px'}` }}
    >
      {isAdmin &&
        <button
          className={isPhoneScreen ? 'button s info' : 'button m info'}
          onClick={() => showForm()}
        >
          Add picture
        </button>}

      <div className="pictures--container" >
        {pictureCards}
      </div >
      <Pagination
        totalCount={pictures.length}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={page => setCurrentPage(page)}
      />
      <PictureForm isEditMode={false} isOpenForm={isOpenForm} hideForm={hideForm} pageSize={pageSize} pictures={pictures} />
    </div >
  )

}
export default Pictures;