import { useContext } from "react"
import { AppContext } from "./AppContext";

import BtnSlider from "./BtnSlider"

import gsap from "gsap";
import { useGSAP } from "@gsap/react";


const dataSlider = [
  {
    id: '1',
    text: 'Glazed partition walls',
  },
  {
    id: '2',
    text: 'Suspended ceilings',
  },
  {
    id: '3',
    text: 'Kitchen furniture'
  },
  {
    id: '4',
    text: 'Carpets'
  },

];

export default function Slider() {


  const { slideIndex, setSlideIndex } = useContext(AppContext);

  const nextSlide = () => {
    if (slideIndex !== dataSlider.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === dataSlider.length) {
      setSlideIndex(1);
    }
  };
  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1)
    }
    else if (slideIndex === 1) {
      setSlideIndex(dataSlider.length);
    }
  };
  const moveDot = index => {
    setSlideIndex(index);
  }

  useGSAP(() => {
    gsap.from(".slider", {
      duration: 1.0,
      opacity: 0,
      delay: 0.5,
    }
    )
  }, [])

  return (
    <div className="slider">
      {dataSlider.map((obj, index) => {
        return (
          <div
            key={obj.id}
            className={slideIndex === index + 1 ? "slide active-anim" : "slide"}>
            <img
              src={`/slider/img${index + 1}.jpg`}
              alt='slider'
            />
          </div>
        )
      })}

      {dataSlider.map((obj, index) => {
        return (
          <div
            key={obj.id}
            className={slideIndex === index + 1 ? "slider--text active-anim" : "slider--text"}
          >
            <div className="slider--text-content">
              <h1> {obj.text}</h1>
            </div>
          </div>
        )
      })}

      <BtnSlider moveSlide={nextSlide} direction={"next"} />
      <BtnSlider moveSlide={prevSlide} direction={"prev"} />
      <div className="container-dot">
        {Array.from({ length: `${dataSlider.length}` }).map((item, index) => (
          <div
            key={index}
            onClick={() => moveDot(index + 1)}
            className={slideIndex === index + 1 ? "dot pointed" : "dot"}
          ></div>
        ))}
      </div>
    </div >
  )
}