
const Sort = ({ searchParams, setSearchParams, items, sortItems, userItems, sortUserItems, showUserItems }) => {

  const updateParams = (e) => {
    const { name, value } = e.target;

    const currentParams = Object.fromEntries([...searchParams])

    const newParams = { ...currentParams, [name]: value }
    setSearchParams(newParams);
    showUserItems ? sortUserItems(userItems, newParams) : sortItems(items, newParams)
  }

  return (
    <div className="items--menu-sort">
      <div className="items--menu-title">
        <h3>Sort with:</h3>
        <label htmlFor="1">Title: </label>
        <input
          id='1'
          type="radio"
          name="sort"
          value="title"
          onChange={updateParams}
          defaultChecked={searchParams.get('sort') === 'title'}
        />
        <label htmlFor="2"> Ticked: </label>
        <input
          id='2'
          type="radio"
          name="sort"
          value="ticked"
          onChange={updateParams}
          defaultChecked={searchParams.get('sort') === 'ticked'}
        />
      </div>
      <div className="items--menu-order">
        <h3>Order with:</h3>
        <label htmlFor="3"> Descending:</label>
        <input
          id='3'
          type="radio"
          name="order"
          value="descending"
          onChange={updateParams}
          defaultChecked={searchParams.get('order') === 'descending'}
        />
        <label htmlFor="4">Ascending:</label>
        <input
          id='4'
          type="radio"
          name="order"
          value="ascending"
          onChange={updateParams}
          defaultChecked={searchParams.get('order') === 'ascending'}
        />
      </div>
    </div>

  );
}

export default Sort;