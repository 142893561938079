import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { navs } from './navsData';
import { MenuIcon } from "./MenuIcon";
import SideNav from "./SideNav";
import { useContext } from "react";
import { AppContext } from "./AppContext";
import LinksTop from "./LinksTop";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP);

const TopNav = () => {

  const { user, tab } = useContext(AppContext);

  const [toggleTab, setToggleTab] = useState('');

  const handleToggle = (id) => {
    setToggleTab(id);
  }

  const handleLeave = () => {
    setToggleTab('')
  }

  const element = <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: '8px', marginLeft: '2px', scale: '0.9', color: 'yellow' }} />;

  const topNavs = user ? navs : navs.filter(nav => nav.title !== 'BLOG');

  const topTabs =
    topNavs.map(nav => {
      let topNavClass;
      if (toggleTab === nav.id) { topNavClass = 'topNav--tabs-tab topNav--tabs-tab-active' } else if (tab === nav.id) { topNavClass = 'topNav--tabs-tab topNav--tabs-tab-active2' } else { topNavClass = 'topNav--tabs-tab' }

      return (
        <span
          key={nav.id}
          className={topNavClass}
          onMouseEnter={() => handleToggle(nav.id)}
          onMouseLeave={() => handleLeave()}

        >{nav.title}
          {toggleTab === nav.id && element}
          <div
            key={nav.id}
            className={toggleTab === nav.id ? `topNav--links-container topNav--links-active` : `topNav--links-container`}
            onMouseEnter={() => handleToggle(nav.id)}
            onMouseLeave={() => handleLeave()}
          // onMouseOver={() => handleToggle(nav.id)}
          // onMouseLeave={() => handleLeave()}
          >
            <LinksTop links={nav.links} classForLinks='topNav--links' classForLink='topNav--link' id={nav.id} />
          </div>
        </span>
      )
    })

  return (
    <div className="topNav">
      <MenuIcon />
      <SideNav />
      <div className="container">
        <div className="topNav--content">
          <div className="topNav--tabs">
            {topTabs}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopNav;


