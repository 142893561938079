import { useContext } from "react";
import { AppContext } from "./AppContext";

export const MenuIcon = () => {
  const { side, setSide } = useContext(AppContext);

  return (
    <div className="menuIcon" onClick={() => {
      setSide(!side);
    }}>
      <div className={!side ? 'menuIcon--bar1' : 'menuIcon--bar1 menuIcon--bar1-change'}></div>
      <div className={!side ? "menuIcon--bar2" : 'menuIcon--bar2 menuIcon--bar2-change'}></div>
      <div className={!side ? "menuIcon--bar3" : 'menuIcon--bar3 menuIcon--bar3-change'}></div>
    </div>
  );
}

