import { useContext, useEffect, useState } from "react";
import { AppContext } from "./AppContext";
import ListItemDetails from "./ListItemDetails"
import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Filter from "./Filter";
import Sort from "./Sort";
import ItemForm from './ItemForm';
import Item from "./Item";
import Slider from "./Slider";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";


const ItemsList = () => {

  const [filter, setFilter] = useState('All');
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState([]);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const { user, isAdmin, isTabletScreen, isDesktopScreen, showUserItems, setShowUserItems } = useContext(AppContext);

  const [userItems, setUserItems] = useState([])

  const location = useLocation();

  const navigate = useNavigate();

  const showForm = () => setIsOpenForm(true);

  const hideForm = () => setIsOpenForm(false);

  const sortItems = (items, params) => {
    if (!Object.keys(params).length > 0) {
      setItems(items);
      return;
    }
    const sorted = [...items].sort((a, b) => {
      const { sort, order } = params;
      switch (order) {
        case 'ascending': {
          return a[sort] > b[sort] ? 1 : -1;
        }

        case 'descending': {
          return a[sort] < b[sort] ? 1 : -1;
        }

        default: {
          return a[sort] > b[sort] ? 1 : -1;
        }
      }
    })
    setItems(sorted);
  };


  const sortUserItems = (items, params) => {
    if (!Object.keys(params).length > 0) {
      setUserItems(items);
      return;
    }
    const sorted = [...items].sort((a, b) => {
      const { sort, order } = params;
      switch (order) {
        case 'ascending': {
          return a[sort] > b[sort] ? 1 : -1;
        }
        case 'descending': {
          return a[sort] < b[sort] ? 1 : -1;
        }
        default: {
          return a[sort] > b[sort] ? 1 : -1;
        }
      }
    })
    setUserItems(sorted);
  };

  useEffect(() => {
    setUserItems(user?.items);

  }, [user]);


  useEffect(() => {

    async function getItems() {
      // const response = await fetch("http://localhost:3001/list");
      const response = await fetch("/list");
      /* tylko z dopisanym proxy w package.json */
      if (!response.ok) {
        const message = `An error occurred:${response.statusText}`
        // const message = await response.text();
        console.log(message);
        return;
      }
      const items = await response.json();
      // console.log('Success, got items:', items);
      setItems(items);

      const params = Object.fromEntries([...searchParams]);

      sortItems(items, params);
    };

    getItems();

    return;

  }, [items.length, location, searchParams]);

  const FILTER_MAP = {
    All: () => true,
    Unchecked: (item) => !item.ticked,
    Ticked: (item) => item.ticked,
  }

  const FILTER_NAMES = Object.keys(FILTER_MAP);

  const filterList = FILTER_NAMES.map(name => (
    <Filter
      key={name}
      name={name}
      setFilter={setFilter}
      isPressed={filter === name}
    />
  ))

  let number = 1;

  const optionItems = (!isAdmin && showUserItems) ? userItems : items;

  function itemsList() {
    return optionItems.filter(FILTER_MAP[filter]).map(item => (
      <ListItemDetails key={item._id} {...item} number={number++} />
    ));
  }

  let sum = 0;

  function itemsSum() {
    optionItems.filter(FILTER_MAP[filter]).forEach(item => (
      sum = sum + item.quantity * item.price
    ));
  }

  user && !isAdmin && showUserItems && itemsSum();

  const list = (isDesktopScreen || isTabletScreen) ? (
    <div className="table-desktop">
      <table>
        {showUserItems ? <caption>User's items</caption> : <caption>Works offered</caption>}
        <thead>
          <tr >
            <th>No</th>
            {/* <th>Type</th> */}
            <th>Title</th>
            {/* <th>Unit</th> */}
            {/* <th>Quantity</th> */}
            {/* <th>Unit price [PLN]</th> */}
            {user && showUserItems && <th>Value</th>}
            {/* <th>Comments</th> */}
            <th>Ticked</th>
            {/* {isAdmin && <th>Edit / Delete</th>} */}
            {/* {!showUserItems && (Boolean(user) && !isAdmin) && <th>Buy</th>} */}
            {user && !isAdmin && !showUserItems ? <th style={{ width: '30%' }} >Details / Add item</th> : <th>Details</th>}
          </tr>
        </thead>
        <tbody>
          {itemsList()}
        </tbody>
        {user && showUserItems && <tfoot>
          <tr>
            <td colSpan="2" style={{ textAlign: 'right' }}>Total value:</td>
            <td>{new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN" }).format(sum)}</td>
            <td colSpan="2"></td>
          </tr>
        </tfoot>}
      </table>
    </div>
  ) : <div className="table-phone">
    {showUserItems ? <h2>User's items</h2> : <h2 >Works offered</h2>}
    <table>
      {itemsList()}
      {user && showUserItems && <tbody>
        <tr>
          <th>Total value:</th>
          <td style={{ backgroundColor: 'yellow' }}>{new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN", maximumFractionDigits: 1 }).format(sum)}</td>
        </tr>
      </tbody>}
    </table>
  </div>;

  useGSAP(() => {
    gsap.from(".items", {
      duration: 1.0,
      opacity: 0,
      delay: 0.5,
    }
    )
  }, [])

  return (
    <>
      <div className="items">
        <div className="items--menu">
          <div className="items--menu-buttons">
            {(Boolean(user) && !isAdmin) && <button
              className={!showUserItems ? "button s info active" : "button s info"}
              onClick={() => {
                setShowUserItems(false);
                navigate("/scope/offer");
              }}
            >WORKS<br /> OFFERED</button>}
            {(Boolean(user) && !isAdmin) &&
              <button
                className={showUserItems ? "button s info active" : "button s info"}
                onClick={() => {
                  setShowUserItems(true);
                  navigate("/scope/offer");
                }}
              >USER's<br /> ITEMS</button>}
            {isAdmin && <button className="button s info add"
              onClick={() => {
                showForm();
              }
              }>ADD ITEM</button>}
          </div>
          <div className="items--menu-container">
            <div className="items--menu-filter">
              <h3>Filter:</h3>
              {filterList}
            </div>
            <Sort searchParams={searchParams} setSearchParams={setSearchParams} items={items} userItems={userItems} sortItems={sortItems} sortUserItems={sortUserItems} showUserItems={showUserItems} />
          </div>
        </div>
        {list}
        < Routes >
          <Route path=":id" element={<Item number={location.state} />} />
        </Routes>
        {!isDesktopScreen && <Slider />}
        <ItemForm isOpenForm={isOpenForm} hideForm={hideForm} />
      </div >
    </>
  )
}

export default ItemsList;

