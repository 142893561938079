
import { Navigate, useRoutes } from "react-router-dom";
import ItemsList from "./ItemsList";
import GoogleMap from "./GoogleMap";

const MiddleComp = ({ title }) => {
  const routes = useRoutes([
    {
      path: "offer/*",
      element: <ItemsList />
    },
    {
      path: "contact",
      element: < GoogleMap />
    },
    {
      path: "*",
      element: <Navigate to="/" replace={true} />
    }
  ])

  return (
    <div className={`main--${title}`} >
      {/* <h2 style={{ color: 'white' }}>{title}</h2> */}
      {routes}
    </div >
  )
}

export default MiddleComp;