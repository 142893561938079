import { NavLink } from 'react-router-dom';
import { AppContext } from './AppContext';
import { useContext } from 'react';


const Links = ({ nav, classForLinks, classForLink }) => {

  const { side, setSide, setTab } = useContext(AppContext);

  const handleClick = () => {
    setSide(!side);
    setTab(nav.id);
  }


  const navLinks = nav.links.map(item => (item.optionSide &&
    <li
      key={item.link}
      className={classForLink}
      onClick={handleClick}
    >
      <NavLink to={`${item.link}`}  >{item.title}</NavLink>
    </li>
  ))

  return (
    <ul className={classForLinks} >
      {navLinks}
    </ul >)
};

export default Links;

