import { Link } from "react-router-dom";


const PictureCard = ({ picture, currentPage, refPicture, pageSize }) => {

  // const img = <img src={`http://localhost:3001/blog/${picture.name}`} alt={`${picture.name}`} />

  const img = <img src={`/blog/${picture.name}`} alt={`${picture.name}`} />
  return (

    <Link
      to={`${picture._id}`}
      className="pictures--picture"
      state={{ currentPage, pageSize }}
      ref={refPicture}
    >
      {img}
      <h2 className="pictures--picture-title">{picture.title}</h2>
    </Link>
  );
}

export default PictureCard;