import { Route, Routes } from "react-router-dom";


export const Panel = ({ children, flex = 0, place }) => {

  return (
    <Routes>
      <Route path='/*' element={
        <div className={`main--content-flex main--content-flex-${place}`} style={{ flex: `${flex}` }}>
          {children}
        </div>
      } />
    </Routes>
  )
}

const SplitScreen = ({ children, leftWidth = 0, middleWidth = 0, rightWidth = 0, }) => {

  const [left, middle, right,] = children;

  return (
    <Routes>
      <Route path='/*' element={
        <div className="main--content">
          <Panel place='left' flex={leftWidth}  >{left}</Panel>
          <Panel place='middle' flex={middleWidth} >{middle}</Panel>
          <Panel place='right' flex={rightWidth} >{right}</Panel>
        </div>}
      />
    </Routes>
  )
}

export default SplitScreen;