import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import { useFormik } from 'formik';
import { object, string, number } from 'yup';
import { useContext, useState } from "react";
import { AppContext } from "./AppContext";

const works = ['', 'Partition walls', 'Ceilings', 'Carpets', 'Furniture'];

const ItemForm = (props) => {

  const { no = 0, isEditing = false, isOpenForm = false, hideForm, _id = '', type, title, quantity, unit, price, comments,/*  ticked = false, */ readOnly = false, disabled = false } = props;

  const { user, setUser, isAdmin, showUserItems, setShowUserItems } = useContext(AppContext);

  const [validateMessage, setValidateMessage] = useState('');

  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      formType: type || '',
      formTitle: title || '',
      formQuantity: quantity || '',
      formUnit: unit || '',
      formPrice: price || '',
      formComments: comments || '',
    },
    validationSchema: object({
      formType: string().required('Please choose option'),
      formTitle: string().required('Please enter title').min(3, 'Title must be 3 characters or more').max(30, 'Title must be 30 characters or less'),
      formQuantity: number().required('Please enter quantity').min(0, 'Quantity must be 0 or a positive number'),
      formUnit: string().required('Please enter unit').min(1, 'Unit must be 1 character or more').max(5, 'Unit must be 5 characters or less'),
      formPrice: number().required('Please enter quantity').min(0, 'Price must be 0 or a positive number'),
      formComments: string().required('Please enter comments').max(80, 'Comments must be 80 characters or less'),
    }),

    onSubmit: values => {
      const itemObject = {
        type: values.formType,
        title: values.formTitle,
        quantity: Number(values.formQuantity),
        unit: values.formUnit,
        price: Number(values.formPrice),
        comments: values.formComments,
      }
      if (isEditing) { isAdmin && changeItem(itemObject) } else {
        { isAdmin ? createItem(itemObject) : changeUserItem({ ...itemObject, _id }) };
      }
    },
  });

  const resetStateOfInputs = () => {
    hideForm();
    formik.handleReset();
    setValidateMessage('');
  }

  async function changeItem(itemObject) {

    try {
      const response = await fetch(`/list/${_id}`, {
        method: "PUT",
        body: JSON.stringify(itemObject),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok && response.status === 404) {
        const message = `Record with id ${_id} ${response.statusText.toLowerCase()}`
        setValidateMessage(message + ' !');
        // console.log(message);
        return;
      } else if (!response.ok && response.status === 400) {
        const message = await response.json();
        setValidateMessage(message.error + ' !');
        // console.log(message.error);
        return;
      }
      // const item = await response.json();
      // console.log("Success, changed item:", item);
      resetStateOfInputs();
      navigate(`/scope/offer/${_id}`, { state: no }, { replace: true });
    }
    catch (error) {
      console.error(error);
    }
  }

  async function createItem(itemObject) {
    try {
      const response = await fetch("/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(itemObject)

      })
      if (!response.ok) {
        const message = await response.json();
        setValidateMessage(message.error + ' !');
        // console.log(message.error);
        return;
      }
      // const item = await response.json();
      // console.log("Success, created item:", item);
      resetStateOfInputs();
      navigate(`/scope/offer`, { replace: true });
    }
    catch (err) {
      console.error(err, err.name, err.message);
    }
  }

  async function changeUserItem(itemObject) {
    try {
      const response = await fetch(`/users/${user._id}/change`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(itemObject)
      })
      if (!response.ok && response.status === 404) {
        const message = `Record with id ${user._id} ${response.statusText.toLowerCase()}`
        setValidateMessage(message + ' !');
        // console.log(message);
        return;
      } else if (!response.ok && response.status === 400) {
        const message = await response.json();
        setValidateMessage(message.error + ' !');
        // console.log(message.error);
        return;
      }
      const updateUser = await response.json();
      // console.log('Sucess, User with an updated item:', updateUser)
      setUser(updateUser);
      setShowUserItems(true);
      resetStateOfInputs();
      navigate(`/scope/offer/${itemObject._id}`, { state: no }, { replace: true });

    }
    catch (err) {
      console.error(err, err.name, err.message);
    }
  }

  const validateMesageComponent = validateMessage.length ? <p className="item--form-validate-message">{validateMessage}</p> : null;

  let formTitle;

  if (isEditing) { formTitle = 'Edit item' } else if (readOnly) {
    formTitle = 'Edit quantity'
  } else formTitle = 'Add item';

  return (
    <Modal handleOnClose={hideForm} isOpen={isOpenForm} shouldBeClosedOnOutsideClick={false}  >
      <div className="item">
        <h1>{formTitle}</h1>
        <form action="" className="item--form" onSubmit={formik.handleSubmit}>
          {validateMesageComponent}
          <label>
            Type:
            <select
              disabled={disabled}
              // required
              name='formType'
              autoFocus={!readOnly}
              value={formik.values.formType || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="item--form-input"
            >
              {works.map(item => (
                <option
                  key={item}
                >{item}</option>
              ))}
            </select>
          </label>
          {formik.touched.formType && formik.errors.formType ? <div className="item--validate-message">{formik.errors.formType}</div> : null}

          <label>
            Title:
            <input
              readOnly={readOnly}
              // required
              placeholder="title"
              type="text"
              name="formTitle"
              value={formik.values.formTitle || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="item--form-input"
            />
          </label>
          {formik.touched.formTitle && formik.errors.formTitle ? <div className="item--validate-message">{formik.errors.formTitle}</div> : null}

          <label>
            Quantity:
            <input
              // required
              placeholder="quantity"
              type="number"
              name="formQuantity"
              autoFocus={readOnly}
              value={formik.values.formQuantity || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="item--form-input"
            />
          </label>
          {formik.touched.formQuantity && formik.errors.formQuantity ? <div className="item--validate-message">{formik.errors.formQuantity}</div> : null}

          <label>
            Unit:
            <input
              readOnly={readOnly}
              // required
              placeholder="unit"
              type="string"
              name="formUnit"
              value={formik.values.formUnit || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="item--form-input"
            />
          </label>
          {formik.touched.formUnit && formik.errors.formUnit ? <div className="item--validate-message">{formik.errors.formUnit}</div> : null}

          <label>
            Unit price:
            <input
              readOnly={readOnly}
              // required
              placeholder="unit price"
              type="number"
              name="formPrice"
              value={formik.values.formPrice || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="item--form-input"
            />
          </label>
          {formik.touched.formPrice && formik.errors.formPrice ? <div className="item--validate-message">{formik.errors.formPrice}</div> : null}

          <label>
            Comments:
            <textarea
              readOnly={readOnly}
              // required
              placeholder="Enter comments"
              name="formComments"
              rows="4"
              columns="2"
              value={formik.values.formComments || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="item--form-input"
            />
          </label>
          {formik.touched.formComments && formik.errors.formComments ? <div className="item--validate-message">{formik.errors.formComments}</div> : null}

          {isAdmin && <button className="button s" type='submit'>{isEditing ? 'SUBMIT' : 'ADD'}</button>}
          {!isAdmin && user && <button className="button s" type='submit'>{showUserItems ? 'SUBMIT' : 'ADD'}</button>}
        </form>
        <button
          type='button'
          className="button s"
          onClick={() => {
            resetStateOfInputs();
          }}
        >CLOSE FORM</button>
      </div>
    </Modal>
  );
}

export default ItemForm;