
const Filter = ({ name, setFilter, isPressed }) => {
  return (
    <button
      className="button s info"
      onClick={() => (
        setFilter(name)
      )}
      style={{
        color: `${isPressed ? '#ffe500' : 'white'}`,
        textDecoration: `${isPressed ? 'underline' : 'none'}`,
        width: 'auto'
      }}
    >
      {name}
    </button >
  );
}

export default Filter;