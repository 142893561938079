import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useState } from 'react';

const FormContact = () => {

  const navigate = useNavigate();
  const [validateMessage, setValidateMessage] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    },

    validationSchema: object({
      firstName: string()
        .max(15, 'First name should be 15 characters or less'),
      // .required('Please enter your first name'),
      lastName: string()
        .max(15, 'Last name should be 15 characters or less'),
      // .required('Please enter your last name'),
      email: string()
        .email('Email address must be a valid address')
        .required('Please enter your email address'),
      message: string()
        .max(40, 'Message should be 40 characters or less')
        .min(2, 'Must be 2 characters or more')
        .required('Message should not be empty'),
    }),

    onSubmit: values => {
      sendMessage(values);
    },
  })


  const resetStateOfInputs = () => {
    formik.handleReset();
    setTimeout(() => setConfirmationMessage(''), 3000)
  }

  async function sendMessage(values) {

    try {
      const response = await fetch("/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values)
      })

      if (!response.ok) {
        const message = await response.json()
        // console.log(message.error);
        setValidateMessage(message.error);
        return;
      }
      // const item = await response.json();
      setValidateMessage('');
      setConfirmationMessage('Message sent');
      // console.log("Success, sent message id:", item);
      resetStateOfInputs();
    }
    catch (err) {
      console.error(err.name, err.message);
    }
  }

  useGSAP(() => {
    gsap.from(".contact--container", {
      duration: 1.0,
      opacity: 0,
      delay: 0.3,
    }
    )
  }, [])


  return (
    <div className="contact--container">

      <form className="contact--form" onSubmit={formik.handleSubmit}>
        {validateMessage && <p style={{ color: 'red', fontWeight: '600' }} >{validateMessage}</p>}
        {confirmationMessage && <p className='contact--text'  >{confirmationMessage}</p>}

        <label>
          First Name
          <input
            // required
            placeholder="Your name.."
            type="text"
            autoFocus
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </label>
        {formik.touched.firstName && formik.errors.firstName ? <p style={{ color: 'red' }}>{formik.errors.firstName}</p> : null}

        <label>
          Last Name
          <input
            // required
            placeholder="Your last name.."
            type="text"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </label>
        {formik.touched.lastName && formik.errors.lastName ? <p style={{ color: 'red' }}>{formik.errors.lastName}</p> : null}
        <label>
          e-mail
          <input
            // required
            placeholder="e-mail"
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </label>
        {formik.touched.email && formik.errors.email ? <p style={{ color: 'red' }}>{formik.errors.email}</p> : null}
        <label>
          Message
          <textarea
            // required
            rows={2}
            placeholder="Write something.."
            name="message"
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </label>
        {formik.touched.message && formik.errors.message ? <p style={{ color: 'red' }}>{formik.errors.message}</p> : null}

        <div className="contact--buttons">
          <button
            className="button s"
            type='button'
            onClick={() => {
              setConfirmationMessage('');
              navigate('/')
            }}
          >Close</button>
          <button className="button s" type='submit'>Send</button>
        </div>
      </form >
    </div>
  );
}
export default FormContact;