import { useLocation } from "react-router-dom";
import LeftSideComp from "./LeftSideComp";
import MiddleComp from "./MiddleComp";
import RightSideComp from "./RightSideComp";
import SplitScreen from "./Split-screen";

const Tablet = () => {

  let location = useLocation();

  const id = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);

  let left;
  let middle;
  let right;

  switch (location.pathname) {
    case '/scope/contact':
      left = 1;
      middle = 1;
      right = 0;
      break;
    case '/scope/offer':
      left = 0;
      middle = 1;
      right = 0;
      break;
    case `/scope/offer/${id}`:
      left = 0;
      middle = 1;
      right = 0;
      break;
    case '/scope/pictures':
      left = 0;
      middle = 0;
      right = 1;
      break;
    case `/scope/pictures/${id}`:
      left = 0;
      middle = 0;
      right = 1;
      break;
    default:
      left = 1;
      middle = 1;
      right = 1;
  }

  return (
    <SplitScreen leftWidth={left} middleWidth={middle} rightWidth={right}  >
      {Boolean(left) && <LeftSideComp title={'topTablet'} />}
      {Boolean(middle) && <MiddleComp title={'middleTablet'} />}
      {Boolean(right) && <RightSideComp title={'bottomTablet'} />}
    </SplitScreen >

  );
}
export default Tablet; 