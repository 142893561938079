import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "./AppContext";
import PictureForm from "./PictureForm"



const Picture = () => {

  const [picture, setPicture] = useState();

  const { isAdmin, pictures, setCurrentPage } = useContext(AppContext);


  const [isOpenForm, setIsOpenForm] = useState(false);

  const hideForm = () => setIsOpenForm(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const location = useLocation();


  async function deletePicture(id) {
    const response = await fetch(`/pictures/${id}`, {
      method: "DELETE"
    });
    if (!response.ok && response.status === 404) {
      // const message = `Record with id ${id} ${response.statusText.toLowerCase()}`
      // console.log(message);
      navigate('/scope/pictures');
      return
    }
    // const picture = await response.json();
    // console.log('Success, deleted picture:', picture);
    let currentPage = Math.ceil((pictures.length - 1) / location.state.pageSize);
    setCurrentPage(currentPage);
    navigate('/scope/pictures');
  }


  useEffect(() => {
    async function getPicture() {
      try {
        const response = await fetch(`/pictures/${id}`);
        if (!response.ok && response.status === 404) {
          const message = `Record with id ${id} ${response.statusText.toLowerCase()}`
          console.log(message)
          navigate('/scope/pictures');
          return;
        }
        const picture = await response.json();
        // console.log('Sucess, got picture:', picture)
        setPicture(picture);
      }
      catch (err) {
        console.log(err);
      }
    }
    getPicture();
  }, [id, location, navigate])


  return picture && (
    <>
      <div className="picture">

        <h1>{picture.title}</h1>

        <div className="picture--group">
          <div className="picture--group-img">
            <img src={`/blog/${picture.name}`} alt={picture.name} />
          </div>
          <div className="picture--group-text">
            <p>{picture.text}</p>
          </div>
        </div>

        <div className="picture--buttons">
          {isAdmin &&
            <>
              <button
                className="button s info"
                onClick={() => setIsOpenForm(true)}
              >Edit</button>
              < button
                className="button s info"
                onClick={() => deletePicture(id)}
              >Delete</button>
            </>
          }
          <button
            className="button s info"
            onClick={() => {
              setCurrentPage(location.state.currentPage || 1)
              navigate('/scope/pictures');
            }}
          >Back</button>
        </div>
      </div >
      <PictureForm isOpenForm={isOpenForm} hideForm={hideForm} {...picture} pageSize={location.state.pageSize} />
    </>

  )
}

export default Picture;

