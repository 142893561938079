import { NavLink } from 'react-router-dom';
import { AppContext } from './AppContext';
import { useContext } from 'react';


const LinksTop = ({ links, ...props }) => {

  const { setSide, setTab } = useContext(AppContext);


  const handleClick = () => {
    setSide(false);
    setTab(props.id);
  }


  const navLinks = links.map(item => (
    item.optionTop && <NavLink
      key={item.link}
      to={`${item.link}`}
      className={props.classForLink}
      // state={item.link}
      onClick={handleClick}
    >{item.title}</NavLink>
  ))

  return (
    <nav className={props.classForLinks} >
      {navLinks}
    </nav >)
};

export default LinksTop;

