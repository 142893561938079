import { useContext } from "react";
import { AppContext } from "./AppContext";
import { Link } from "react-router-dom";
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useLayoutEffect, useState } from 'react';


gsap.registerPlugin(ScrollTrigger);

const GSAPLanding = () => {

  const { setSlideIndex } = useContext(AppContext);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const container = useRef();

  const element = <FontAwesomeIcon icon={faChevronDown} className="banner--offer-arrow" />;

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }

  useLayoutEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }

    window.addEventListener('resize', updateDimension);

    return (() => {
      window.removeEventListener('resize', updateDimension);
    })

  }, [screenSize]);



  useGSAP(
    () => {

      let items = gsap.utils.toArray(".banner--picture");

      gsap.set(".banner", { autoAlpha: 1.0, });

      items?.forEach(function (item, index) {
        const tl = gsap
          .timeline({ paused: true })
          .to(item, {
            scale: 1.10,
          })
        item.addEventListener("mouseenter", () => tl.play());
        item.addEventListener("mouseleave", () => tl.reverse());
      })

      gsap.timeline({
        onUpdate: scrollTop,
        onComplete: () => {
          (screenSize.width > 992) ? enableScrollTimeline() : scrollBottom();
        }
      })
        .set(".banner--wrappers-one", { autoAlpha: 1 }, "<+=0.5")
        .from(".banner--words-headings h1", { xPercent: -100, opacity: 0, duration: 1 }, "<")
        .from(".banner--words-headings h2", { xPercent: 100, opacity: 0, duration: 1 }, "<+0.25")
        .duration(3)

      let mm = gsap.matchMedia();
      let breakpoints = {
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400
      };

      mm.add({
        phone: `(max-width:${breakpoints.sm}px)`,
        tablet: `(min-width:${breakpoints.sm + 1}px) and (max-width:${breakpoints.xl}px)`,
        desktop: `(min-width:${breakpoints.lg + 1}px)`,
      }, (context) => {
        // let { phone, tablet, desktop } = context.conditions;


        gsap.timeline({ delay: 3.0 })
          .set(".banner--wrappers-two", { autoAlpha: 1 })
          .from(".banner--offer", { width: 0, duration: 0.8, ease: "none" })
          .to(".banner--wrappers-three",
            {
              keyframes: {
                "0%": {
                  scale: 1,
                },

                "100%": {
                  scale: 1,
                },
              },
              duration: 2,
            }
          )
          .from(".banner--picture", {
            keyframes: {
              "0%": {
                yPercent: 300,
              },
              "80.9%": {
                yPercent: 0,
                ease: "bounce"
              },
              "100%": {
                yPercent: 0,
              },
            },

            // rotation: tablet ? 0 : -0,
            duration: 3.0,
          }, "<")
          .to(".banner--wrappers-three :nth-child(2) .banner--picture", { scale: 1.10 })
          .to(".banner--wrappers-three :nth-child(2) .banner--picture", { scale: 1.0 })
      });

      let scrollTimeline = gsap.timeline({ paused: true })
        .to(".banner--words-headings h1", { y: -100, opacity: 0 }, "<")
        .to(".banner--words-headings h2", { y: 100, opacity: 0 }, "<")
        .set(".banner--words-rotator", { opacity: 1 }, "<")
        .from(".banner--words-rotator h1", { opacity: 0, scale: 0, stagger: 1 }, "<")
        .to(".banner--words-rotator h1", { opacity: 0, scale: 1.7, stagger: 1 }, "<+1")

      const element = <div className="banner--wrappers-three"></div>

      function scrollTop() {
        window.scrollTo({ top: 0, left: 0, behavior: "auto" });
      };

      function scrollBottom() {
        window.scrollTo({ top: screenSize.height, left: 0, behavior: "smooth" });

      };

      function enableScrollTimeline() {
        // gsap.set(".moreContent", { display: "block" })

        ScrollTrigger.create({
          trigger: ".banner",
          start: "top top",
          pin: true,
          end: "+=" + window.innerHeight * 2,

          // pinSpacing: true,
          animation: scrollTimeline,
          scrub: 0.5,
          // scrub: 1,
          // markers: true,
        })
      }
    }, { revertOnUpdate: false });

  const images = [1, 2, 3, 4];

  const pictures = images.map(image => (
    <Link
      key={image}
      to='scope/offer'
      onClick={() => setSlideIndex(image)}
    >
      <div
        key={image}
        className="banner--picture"
        style={{ backgroundImage: `url("/slider/img${image}.jpg")` }}
      />
    </Link>)
  )
  return (
    <>
      <div
        className='banner'
        ref={container}
      >
        <div className="container">
          <div className="banner--wrappers">
            <div className="banner--wrappers-one" >
              <div className="banner--words">
                <div className="banner--words-headings">
                  <h1>Fit-out services</h1>
                  <h2>Welcome to this site</h2>
                </div>
                <div className="banner--words-rotator">
                  <h1>Our mission is to deliver high end interior projects</h1>
                  <h1>Our customers are extremely satisfied</h1>
                  <h1>There is no competition</h1>
                </div>
              </div>
            </div>
            <div className="banner--wrappers-two">
              <div className="banner--offer">
                <h1 className="banner--offer-text">Check our offer ! </h1>
                {element}
              </div>
            </div>
            <div className="banner--wrappers-three">
              {pictures}
            </div>
            <div className="moreContent">

            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default GSAPLanding;