import { useContext, useEffect, useState } from "react";
import { AppContext } from "./AppContext";
import ItemDetails from "./ItemDetails"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ItemForm from './ItemForm';
import { nanoid } from 'nanoid';


const Item = ({ number }) => {

  const [item, setItem] = useState({ _id: '', type: '', title: '', quantity: 0, price: 0, comments: '', ticked: false });
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const { user, setUser, isAdmin, showUserItems, setShowUserItems, isDesktopScreen, isTabletScreen } = useContext(AppContext);

  const { type, title, quantity, unit, price, comments } = item;

  const { id } = useParams();


  const navigate = useNavigate();
  const location = useLocation();

  const showForm = () => setIsOpenForm(true);
  const hideForm = () => setIsOpenForm(false);


  async function deleteItem(id) {
    try {
      const response = await fetch(`/list/${id}`, {
        method: "DELETE"
      })
      if (!response.ok && response.status === 404) {
        // const message = `Record with id ${id} ${response.statusText.toLowerCase()}`
        // console.log(message);
        return;
      }
      // const deleteItem = await response.json()
      // console.log('Success, deleted item:', deleteItem)
      navigate("/scope/offer");
    }
    catch (error) {
      console.log(error)
    }
  }

  async function removeUserItem() {
    try {
      const response = await fetch(`/users/${user._id}/remove`, {
        method: "PATCH",
        body: JSON.stringify({ _id: id }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok && response.status === 404) {
        // const message = `Record with id ${user._id} ${response.statusText.toLowerCase()}`
        // console.log(message);
        return;
      } else if (!response.ok && response.status === 400) {
        // const message = await response.json();
        // console.log(message.error);
        return;
      }
      const data = await response.json();
      // console.log("Success, user without deleted item:", data);
      setUser(data);
      setShowUserItems(true);
      navigate("/scope/offer");

    }
    catch (error) {
      console.log(error)
    }
  }


  async function createUserItem() {
    try {
      const response = await fetch(`/users/${user._id}/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ type, title, quantity, unit, price, comments, _id: nanoid() })

      })
      if (!response.ok && response.status === 404) {
        // const message = `Record with id ${user._id} ${response.statusText.toLowerCase()}`
        // console.log(message);
        return;
      } else if (!response.ok && response.status === 400) {
        // const message = await response.json();
        // console.log(message.error);
        return;
      }
      const updatedUser = await response.json();
      // console.log('Success, User with a new item:', updatedUser);
      setUser(updatedUser);
      setShowUserItems(true);
      navigate("/scope/offer", { replace: true });
    }
    catch (err) {
      console.error(err, err.name, err.message);
    }
  }

  useEffect(() => {
    async function getItem() {
      try {
        const response = await fetch(`/list/${id}`);
        if (!response.ok && response.status === 404) {
          // const message = `Record with id ${id} ${response.statusText.toLowerCase()}`
          // window.alert(message);
          // console.log(message)
          navigate('/scope/offer');
          return;
        }
        const item = await response.json();
        // console.log('Success, got item:', item);
        setItem(item);
      }
      catch (err) {
        console.log(err.name, err.message);
      }
    };

    !showUserItems && getItem();

  }, [id, location, navigate, showUserItems]);

  useEffect(() => {
    if (!isAdmin && user) {
      setIsReadOnly(true);
    }
  }, [isAdmin, user])

  const buttons = <div className="table--buttons" >
    <button className="button s" onClick={() => navigate("/scope/offer")}>HIDE</button>

    {showUserItems && Boolean(user) && !isAdmin && <button className="button s" onClick={removeUserItem}> REMOVE </button>}

    <div className="table--buttons-group" >
      {isAdmin && <button
        className="button s"
        onClick={
          () => {
            setEditing(true);
            showForm();
          }
        }
      >EDIT</button>}

      {(!isAdmin && user && showUserItems) && <button
        className="button s"
        onClick={
          () => {
            setEditing(false);
            showForm();
          }
        }
      >EDIT QUANTITY</button>}

      {(!isAdmin && !showUserItems && user) && <button
        className="button s"
        onClick={
          () => {
            createUserItem()
          }
        }
      >ADD</button>}
      {isAdmin && <button
        className="button s"
        onClick={() => deleteItem(item._id)}
      >DELETE</button>}
    </div>
  </div>

  let optionItem;

  if (!isAdmin && Boolean(user) && showUserItems) { optionItem = user.items.find(item => item._id === id) } else if (!isAdmin && !Boolean(user)) { optionItem = item } else { optionItem = item }

  return optionItem && (
    <>
      {(isDesktopScreen || isTabletScreen) ? (
        <div className="table-desktop">
          <table>
            <caption>{optionItem.title}</caption>
            <thead>
              <tr>
                <th>No</th>
                {/* <th>Title</th> */}
                <th>Unit</th>
                {user && showUserItems && <th>Quantity</th>}
                <th>Unit price</th>
                {/* <th>Value [PLN]</th> */}
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              <ItemDetails {...optionItem} />
            </tbody>
          </table>
          {!user && !isAdmin && <h3 style={{ color: 'yellow' }}>Register to calculate the value.</h3>}
          <h3 style={{ color: 'yellow' }}>Prices are for testing purposes and do not constitute an offer.</h3>
          {buttons}
        </div>
      ) : <div className="table-phone">
        <h2> {optionItem.title}</h2>
        <ItemDetails {...optionItem} />
        {!user && !isAdmin && <h3 style={{ color: 'yellow' }}>Register to calculate the value</h3>}
        <h3 style={{ color: 'yellow' }}>Prices are for testing purposes and do not constitute an offer</h3>
        {buttons}
      </div>}
      <ItemForm isEditing={isEditing} isOpenForm={isOpenForm} hideForm={hideForm} {...optionItem} no={number} readOnly={isReadOnly} disabled={isReadOnly} />
    </>

  )
}

export default Item;

