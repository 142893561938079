
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';
import AppProvider from './components/AppContext';
import TopNav from './components/TopNav.jsx';
import Footer from './components/Footer';
import Header from './components/Header';
import MatchMediaWrapper from './components/MatchMediaWrapper';
import Desktop from './components/Desktop';
import Tablet from './components/Tablet';
import Phone from './components/Phone';
// import ScrollTop from './components/scroll-top.js';
import GSAPLanding from './components/GSAPLanding.jsx';

function App() {

  const routes = useRoutes([
    {
      path: "/",
      element: < GSAPLanding />
    },
    {
      path: "/scope/*",
      element: <MatchMediaWrapper DesktopContent={Desktop} TabletContent={Tablet} PhoneContent={Phone} />
    },
    {
      path: "/*",
      element: <Navigate to="/" />
    }
  ]
  );
  return (routes);
}

const AppWraper = () => {

  return (
    <BrowserRouter>
      <AppProvider>
        {/* <ScrollTop /> */}
        <Header />
        <TopNav />
        <App />
        <Footer />
      </AppProvider>
    </BrowserRouter>
  )
}

export default AppWraper
