import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function BtnSlider({ direction, moveSlide }) {

  return (
    <button
      onClick={() => {
        moveSlide();
      }}
      className={direction === "next" ? "btn-slide next" : "btn-slide prev"}>
      {direction === "next" ? <FontAwesomeIcon icon={faArrowRight} /> :
        <FontAwesomeIcon icon={faArrowLeft} />}
    </button>
  )
}

export default BtnSlider;