import { useContext } from "react";
import { AppContext } from "./AppContext";
import Contact from "./Contact";

const Footer = () => {

  const { side } = useContext(AppContext);

  return (
    <div className="footer">
      <div className="container">
        <div className={side ? "footer--content footer--content-side" : "footer--content"}>
          <Contact />
        </div>
      </div>
    </div>
  );
}
export default Footer;