import { useContext } from "react";
import { AppContext } from "./AppContext";
import { useLocation } from "react-router-dom";

const ItemDetails = (props) => {

  const { _id = '', /* type = '', title = '', */ unit = '', quantity = 0, price = 0, comments = '', /* ticked = false */ } = props;

  const { user, isDesktopScreen, isTabletScreen, showUserItems } = useContext(AppContext);

  const location = useLocation();

  const itemListPhone = <div className="table-phone--container" key={_id}>
    <table >
      <tbody>
        <tr>
          <th>No</th>
          <td>{location.state}</td>
        </tr>
        {/* <tr>
          <th>Type</th>
          <td>{type}</td>
        </tr> */}
        {/* <tr>
          <th>Title</th>
          <td>{title}</td>
        </tr> */}
        <tr>
          <th>Unit</th>
          <td>{unit}</td>
        </tr>
        {user && showUserItems && <tr>
          <th>Quantity</th>
          <td>{quantity}</td>
        </tr>}
        <tr>
          <th>Unit price </th>
          <td>{new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN", maximumFractionDigits: 1 }).format(price)}</td>
        </tr>
        {/* <tr>
          <th>Value [PLN]</th>
          <td>{quantity * price}</td>
        </tr> */}
        <tr>
          <th>Comments</th>
          <td>{comments}</td>
        </tr>
        {/* <tr>
          <th>Ticked</th>
          <td>
            <div className="table-phone--input">
              <input
                id={_id}
                type='checkbox'
                checked={ticked}
                onChange={(e) => {
                  toggleTaskCompleted(_id, e.target.checked);
                }}
              />
              <label
                htmlFor={_id}
              >{ticked ? 'true' : 'false'}</label>
            </div>
          </td>
        </tr> */}
      </tbody>
    </table>
  </div>


  const itemListDesktop = <tr key={_id}>
    <td>{location.state}</td>
    {/* <td>{type}</td> */}
    {/* <td>{title}</td> */}
    <td>{unit}</td>
    {user && showUserItems && <td>{quantity}</td>}
    <td>{new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN", maximumFractionDigits: 1 }).format(price)}</td>
    {/* <td>{quantity * price}</td> */}
    <td>{comments}</td>
    {/* <td>
      <div className="table-desktop--input">
        <input
          id={_id}
          type='checkbox'
          checked={ticked}
          onChange={(e) => {
            toggleTaskCompleted(_id, e.target.checked);
          }}
        />
        <label
          htmlFor={_id}
        >{ticked ? 'true' : 'false'}</label>
      </div>
    </td> */}
  </tr >

  return (
    <>
      {(isDesktopScreen || isTabletScreen) ? itemListDesktop : itemListPhone}
    </>
  );
}

export default ItemDetails;