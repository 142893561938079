
import { createContext, useState } from "react";

export const AppContext = createContext(null);

const AppProvider = ({ children }) => {

  const [isDesktopScreen, setIsDesktopScreen] = useState(false);

  const [isDesktopScreen_1, setIsDesktopScreen_1] = useState(false);
  const [isDesktopScreen_2, setIsDesktopScreen_2] = useState(false);


  const [isTabletScreen, setIsTabletScreen] = useState(false);

  const [isTabletScreen_1, setIsTabletScreen_1] = useState(false);
  const [isTabletScreen_2, setIsTabletScreen_2] = useState(false);


  const [isPhoneScreen, setIsPhoneScreen] = useState(false);
  const [user, setUser] = useState(null);
  const [showUserItems, setShowUserItems] = useState(false);
  const [side, setSide] = useState(false);

  const [pictures, setPictures] = useState([]);
  const [slideIndex, setSlideIndex] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [tab, setTab] = useState(0);

  const isAdminLogged = process.env.REACT_APP_ADMIN_TYPE;

  const isAdmin = user?.accessLevel === Number(isAdminLogged);

  return (
    <AppContext.Provider value={{
      user,
      setUser,
      isAdmin,
      side,
      setSide,
      isDesktopScreen,
      setIsDesktopScreen,
      isDesktopScreen_1,
      setIsDesktopScreen_1,
      isDesktopScreen_2,
      setIsDesktopScreen_2,
      isTabletScreen,
      setIsTabletScreen,
      isTabletScreen_1,
      setIsTabletScreen_1,
      isTabletScreen_2,
      setIsTabletScreen_2,
      isPhoneScreen,
      setIsPhoneScreen,
      pictures,
      setPictures,
      showUserItems,
      setShowUserItems,
      slideIndex,
      setSlideIndex,
      currentPage,
      setCurrentPage,
      tab,
      setTab,
    }} >
      {children}
    </AppContext.Provider >
  )
}

export default AppProvider;