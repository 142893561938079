import { useContext, useRef, useState } from "react";
import { AppContext } from "./AppContext";
import LoginForm from "./LoginForm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import gsap from "gsap";

import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP);

const Header = ({ timeline }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [register, setRegister] = useState(false);
  const { user, setUser, isAdmin, setShowUserItems, setSide, setTab } = useContext(AppContext);

  const location = useLocation();
  const navigate = useNavigate();


  let words = location.pathname.split('/');

  const container = useRef();

  const handleOnClose = () => setIsModalOpen(false);

  const handleOnClickLog = () => {
    if (Boolean(user)) {
      setUser(null);
      setShowUserItems(false);
      setTab(0);
      navigate('/');
    } else {
      setIsModalOpen(true);
    }
  }

  const setProperlyLabel = Boolean(user) ? 'LOG OUT' : 'LOG IN';

  const handleOnClear = () => {
    setRegister(false);
  }

  const handleOnClick = () => {
    setRegister(true);
    setIsModalOpen(true);
  }

  useGSAP(() => {

    gsap.set([".header--text", ".header--logo"], { scale: 1, opacity: 1 });

    gsap.timeline().from(".header--text", { duration: 5, scale: 0, opacity: 0 }, "1")
      .from(".header--logo", { duration: 3, x: -300, opacity: 0, ease: 'power3.out' }, "-=3");
  }, { dependencies: [], revertOnUpdate: false })


  return (
    <div className="header">
      <div className="container">
        <div className="header--content" >
          <div className="header--content-group" ref={container}>
            {words[2] === 'pictures' ?
              <Link
                to='/'
                onClick={() => {
                  setSide(false);
                  setTab(0);
                }}
              >
                <img src="\images\windsurfing-svgrepo-com.svg" className="header--logo" alt='logo_1' />
              </Link> :
              <Link
                to='/'
                onClick={() => {
                  setSide(false);
                  setTab(0);
                }}
              >
                <img src="\images\penrose-triangle.svg" className="header--logo" alt='logo_2' />
                {/* <img src="\images\building-town-svgrepo-com.svg" className="header--logo" alt='logo_2' /> */}
              </Link>}

            <h1 className="header--text">
              {location.pathname === '/scope/pictures' ?
                'BLOG' : 'THE COMPANY'}
            </h1>
          </div>
          <div className="header--content-login">
            {isAdmin && <h3>ADMIN</h3>}

            {Boolean(user) && !isAdmin && <h3>USER</h3>}

            <button className="button s primary" onClick={handleOnClickLog}>{setProperlyLabel}</button>

            {!Boolean(user) && <button className="button s primary" onClick={handleOnClick}>REGISTER</button>}
          </div>
          <LoginForm handleOnClear={handleOnClear} register={register} handleOnClose={handleOnClose} isModalOpen={isModalOpen} />
        </div>
      </div>
    </div>
  );
}
export default Header;