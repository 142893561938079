import { Navigate, Route, Routes } from "react-router-dom";
import FormContact from "./FormContact";
import Slider from "./Slider";



const LeftSideComp = ({ title }) => {
  return (
    <div className={`main--${title}`}>
      {/* <h2 style={{ color: 'black' }}>{title}</h2> */}
      <Routes>
        <Route path='contact' element={<FormContact />} />
        <Route path="offer/*" element={<Slider />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default LeftSideComp;

