import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from './AppContext';
import { navs } from './navsData';


const Contact = () => {

  const { setTab } = useContext(AppContext);


  const nav = navs.find(nav => nav.title === 'CONTACT')

  const handleClick = () => {
    setTab(nav.id);
  }

  return (
    <div className="contact">
      <div className="contact--email">
        <span>
          <FontAwesomeIcon icon={faEnvelope} />
        </span>
        <a href="mailto:example@email.com">example@email.com</a>
      </div>

      <div className='contact--phone'>
        <span>
          <FontAwesomeIcon icon={faPhone} />
        </span>
        <p>+48 123 456 789</p>
      </div>
      <Link
        to='scope/contact'
        onClick={handleClick}
      >Contact Us</Link>
    </div >
  );
}

export default Contact;