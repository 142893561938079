
import { APIProvider, Map, AdvancedMarker, Pin, InfoWindow } from "@vis.gl/react-google-maps";
import { useState } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";



const position = { lat: 52.24778, lng: 21.01526 }

const GoogleMap = () => {
  const [open, setOpen] = useState(false);

  useGSAP(() => {
    gsap.from(".google", {
      duration: 1.0,
      opacity: 0,
      delay: 0.3,
    }
    )
  }, [])

  return (
    <APIProvider
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
    >
      <div className='google' >
        <Map
          mapId={'4504f8b37365c3d0'}
          defaultZoom={10}
          defaultCenter={position}
          gestureHandling={"cooperative"}
          disableDefaultUI={false}
        >
          <AdvancedMarker
            position={position}
            onClick={() => setOpen(true)}
          >
            <Pin
              background={"#FBBC04"}
              glyphColor={"#000"}
              borderColor={"#000"}
            />
          </AdvancedMarker>
          {open &&
            <InfoWindow position={position} onCloseClick={() => setOpen(false)}>
              I'am in Warsaw
            </InfoWindow>}
        </Map>
      </div>
    </APIProvider >
  );
}

export default GoogleMap;