
import { useLayoutEffect, useState } from 'react';
import { navs } from './navsData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import LinksSide from './LinksSide';
import { useContext } from 'react';
import { AppContext } from './AppContext';
import Media from './Media';


const element1 = <FontAwesomeIcon
  icon={faChevronDown}
  style={{ marginLeft: '2px', scale: '0.9' }}
/>

const element2 = <FontAwesomeIcon
  icon={faChevronUp}
  style={{ marginLeft: '2px', scale: '0.9' }}
/>

const TabGroup = ({ nav }) => {
  const [dropTab, setDropTab] = useState('');
  const [flag, setFlag] = useState(false);


  const handleDropTab = (id) => {
    setDropTab(id);
  }

  const handleSetFlag = () => {
    setFlag(!flag);
  }

  return (
    <li className='sideNav--button-container'>
      <button
        links={nav.links}
        className={dropTab === nav.id && flag ? 'sideNav--button sideNav--button-active' : 'sideNav--button'}
        onClick={() => {
          handleDropTab(nav.id);
          handleSetFlag();
        }}>
        {nav.title}{dropTab === nav.id && flag ? element2 : element1}
      </button >
      <div
        key={nav.id}
        className={dropTab === nav.id && flag ? `sideNav--links-container sideNav--links-active ` : `sideNav--links-container`}
      ><LinksSide nav={nav} classForLinks={'sideNav--links'} classForLink={'sideNav--link'} />
      </div>
    </li >
  );
}




const SideNav = () => {

  const { side, user } = useContext(AppContext);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }

  useLayoutEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }

    window.addEventListener('resize', updateDimension);

    return (() => {
      window.removeEventListener('resize', updateDimension);
    })

  }, [screenSize]);



  const sideNavs = user ? navs : navs.filter(nav => nav.title !== 'BLOG');

  const sideTabs = sideNavs.map(nav => (
    <TabGroup key={nav.id} nav={nav} />
  ))


  return (
    <>
      <div className={side ? "sideNav " : "sideNav--hide"} style={{ height: `${screenSize.height - 3 * 20}px` }}>
        <ul >
          {sideTabs}
        </ul>
        <Media />
      </div>
    </>
  );
}
export default SideNav;