import { useContext } from "react";
import { AppContext } from "./AppContext";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const ListItemDetails = (props) => {

  const { number = 0, _id = '', type = '', title = '', quantity = 0, price = 0, /* comments = '', */ ticked = false, } = props;

  const location = useLocation();

  const { user, setUser, isAdmin, slideIndex, setSlideIndex, isDesktopScreen, isTabletScreen, showUserItems } = useContext(AppContext);

  const navigate = useNavigate();

  let slider

  switch (type) {
    case "Partition walls":
      slider = 1
      break;
    case "Ceilings":
      slider = 2
      break;
    case "Furniture":
      slider = 3
      break;
    case "Carpets":
      slider = 4
      break;
    default:
      slider = 1
  }

  const tabLocation = location.pathname.split('/');


  async function toggleTaskCompleted(ticked) {

    try {
      const response = showUserItems ? await fetch(`/users/${user._id}/toggle`, {
        method: "POST",
        body: JSON.stringify({ ticked, _id }),
        headers: {
          'Content-Type': 'application/json'
        }
      }) : await fetch(`/list/${_id}/toggle`, {
        method: "POST",
        body: JSON.stringify({ ticked }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok && response.status === 404) {
        // const message = showUserItems ? `Record with id ${user._id} ${response.statusText.toLowerCase()}` : `Record with id ${_id} ${response.statusText.toLowerCase()}`
        // console.log(message);
        return;
      } else if (!response.ok && response.status === 400) {
        // const message = await response.json();
        // console.log(message.error);
        return;
      }

      if (showUserItems) {
        const modifiedUser = await response.json();
        // console.log('Sucess, modified user:', modifiedUser)
        setUser(modifiedUser);
        return;
      } else {
        // const modifiedItem = await response.json();
        // console.log('Sucess, modified item:', modifiedItem)
      }
      navigate(`/scope/offer`);
    }
    catch (error) {
      console.error(error);
    }
  }

  let text

  if (user && !isAdmin && !showUserItems) { text = 'See price / Add' } else if (user && !isAdmin && showUserItems) { text = 'See details' } else { text = 'See price' };


  const itemListPhone =

    <tbody style={{ backgroundColor: slideIndex === slider && _id === tabLocation[3] && 'yellow' }}>
      <tr>
        <th>No</th>
        <td>{number}</td>
      </tr>
      {/* <tr>
        <th>Type</th>
        <td>{type}</td>
      </tr> */}
      <tr>
        <th>Title</th>
        <td >{title}</td>
      </tr>
      {/* <tr>
        <th>Unit</th>
        <td>m2</td>
      </tr> */}
      {/* <tr>
        <th>Quantity</th>
        <td>{quantity}</td>
      </tr> */}
      {/* <tr>
        <th>Unit price [PLN]</th>
        <td>{price}</td>
      </tr> */}
      {user && showUserItems && <tr>
        <th>Value</th>
        <td>{new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN", maximumFractionDigits: 1 }).format(quantity * price)}</td>
      </tr>}
      {/* <tr>
          <th>Comments</th>
          <td>{comments}</td>
        </tr> */}
      <tr>
        <th>Ticked</th>
        <td>
          <div className="table-phone--input">
            <input
              id={_id}
              type='checkbox'
              checked={ticked}
              onChange={(e) => {
                toggleTaskCompleted(e.target.checked);
              }}
            />
            {/* <label
                htmlFor={_id}
              >{ticked ? 'true' : 'false'}</label> */}
          </div>
        </td>
      </tr>
      <tr>
        {user && !isAdmin && !showUserItems ? <th>Details / Add item</th> : <th>Details</th>}
        <td
        >
          {slideIndex === slider && _id === tabLocation[3] ? <button
            onClick={() => {
              navigate("/scope/offer");
            }}>Hide details</button> : <NavLink
              to={_id}
              state={number}
              onClick={() => setSlideIndex(slider)}
            >{text}</NavLink>}
        </td>
      </tr>
    </tbody>

  const itemListDesktop = <tr style={{ backgroundColor: slideIndex === slider && _id === tabLocation[3] && 'yellow' }} className="row" key={_id}>
    <td >{number}</td>
    {/* <td >{type}</td> */}
    <td style={{ overflowWrap: 'break-word' }}>{title}</td>
    {/* <td>m2</td> */}
    {/* <td>{quantity}</td> */}
    {/* <td>{price}</td> */}
    {user && showUserItems && <td>{new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN", maximumFractionDigits: 1 }).format(quantity * price)}</td>}
    {/* <td>{comments}</td> */}
    <td >
      <div className="table-desktop--input">
        <input
          id={_id}
          type='checkbox'
          checked={ticked}
          onChange={(e) => {
            toggleTaskCompleted(e.target.checked);
          }}
        />
        {/* <label
          htmlFor={_id}
        >{ticked ? 'true' : 'false'}</label> */}
      </div>
    </td>

    <td
      style={{ width: '25%' }}
    >
      {slideIndex === slider && _id === tabLocation[3] ? <button
        onClick={() => navigate("/scope/offer")}>Hide details</button> : <NavLink
          to={_id}
          state={number}
          onClick={() => setSlideIndex(slider)}
        >{text}</NavLink>}
    </td>
  </tr >
  return (
    <>
      {(isDesktopScreen || isTabletScreen) ? itemListDesktop : itemListPhone}
    </>
  );
}

export default ListItemDetails;