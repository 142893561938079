

export const navs = [

  {
    id: '1',
    title: 'OFFER',
    links: [
      { link: 'scope/offer', title: 'offer', optionTop: true, optionSide: true },
    ]
  },
  {
    id: '2',
    title: 'BLOG',
    links: [
      { link: 'scope/pictures', title: 'pictures', optionTop: true, optionSide: true },
    ]
  },
  {
    id: '3',
    title: 'CONTACT',
    links: [
      { link: 'scope/contact', title: 'contact', optionTop: true, optionSide: true },
    ]
  }
]




